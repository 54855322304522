import React from 'react';

import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Grid2Section() {
  return (
    <div className="site-section">
      <Container>
        <Row>

          <Col md={6}>
            <img
              src="/images/austin.jpg"
              width="100%"
              alt="UT Austin Campus"
            />

          </Col>
          <Col md={6}>
            <h1 className="sub-heading">WHO WE ARE</h1>
            <p className="block-41-text">
              We’re a group of volunteers with roots in Austin and
              community around the globe.
              In pursuit of our mission to make the world a better place we
              designed this platform to reduce senior and youth
              isolation while providing mentorship and mutual friendship.

              While Big & Mini was born in response to the increased
              loneliness and separation caused by the COVID-19 pandemic,
              our goal of developing connections across generations
              and creating a more convinient way to volunteer is everlasting.

            </p>

          </Col>

        </Row>
      </Container>

    </div>
  );
}
export default Grid2Section;
