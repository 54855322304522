// pages/404.js
// export default function Custom404() {
//   return <h1>404 - Page Not Found</h1>
// }

import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Head from 'next/head';
import { FaHome } from 'react-icons/fa';
import Footer from '@/components/common/widgets/Footer';
import HeroSection from '@/components/homepage/HeroSection';
import Layout from '@/components/common/layouts/Layout';
import IntroSection from './aboutpage/IntroSection';
import CarouselSection from './aboutpage/CarouselSection';
import IconSection from './aboutpage/IconSection';
import ActionSection from './aboutpage/ActionSection';
import Grid1Section from './aboutpage/Grid1Section';
import Grid2Section from './aboutpage/Grid2Section';

function Custom404() {
  return (
    <Layout>
      <Head>
        <title>Big & Mini: 404 Page</title>
        <meta name="description" content="All about Big & Mini: who we are, what we do, where we are from." />
      </Head>
      <div id="about">
        <ParallaxProvider>
          <HeroSection
            image="/images/ab_6.jpg"
            alt="Elderly woman next to red flowers"
            topSpace={(
              <div>
                <br />
              </div>
                )}
            title="404 Error"
            description="The page you are looking for cannot be found."
            otherContent={(
              <div>
                <p>Let's just go home:</p>
                <a href="/" style={{ fontSize: '60px' }}><FaHome /></a>
              </div>
                )}
          />
        </ParallaxProvider>

        <Footer />
      </div>
    </Layout>
  );
}

export default Custom404;
