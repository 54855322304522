import React from 'react';

import Container from 'react-bootstrap/Container';

function IntroSection() {
  return (
    <div className="site-section">
      <Container fluid="md" className="centered">
        <h1>
          Hi! We’re Big & Mini...
        </h1>
        <br />
        <p className="lead">
          And we’re big people-people. As members of the newest
          generation of creators, we’ve grown up in a world where
          seeing a friend is as simple as pressing a button on a
          phone. Being constantly connected to others is all we’ve ever
          known and we couldn’t be happier— making it all the more
          disappointing that an entire generation of older individuals
          with the best stories on Earth are left out of the communication
          loop. At Big & Mini, we asked why this was
          the case. It wasn’t the lack of technology; nearly all seniors
          we talked to had some form of mobile device.
          Nor was it the learning curve associated with
          technology; many of them were better at using
          Facebook than we were! It was simply a communication
          blip— somehow, in the age of social networking and video calls,
          nobody thought to connect the Bigs of society with the Minis. So we set out to resolve this, and soon Big & Mini was born. What you see here today is our best effort at using technology to shrink the generational divide into a small crack. We hope that through shared stories, new memories, and new friendships, we can connect everyone, one big and mini match at a time.
        </p>
      </Container>
    </div>
  );
}

export default IntroSection;
