import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {FaGraduationCap, FaCode, FaChartBar} from 'react-icons/fa';

const iconHeight = '60px';
function IconSection() {
  return (
    <div className="icon-section">
      <Container fluid="md">
        <Row>

          <Col md={4}>
            <FaGraduationCap/>
            <p className="lead">
              50 States
            </p>
          </Col>
          <Col md={4}>
            <FaCode/>
            <p className="lead">
              37,000+ Lines of Code
            </p>
          </Col>
          <Col md={4}>
            <FaChartBar/>
            <p className="lead">
              27+ Countries
            </p>
          </Col>

        </Row>
      </Container>
      <style jsx global>
        {`
          .icon-section svg {
            font-size: 50px;
            margin-top: 10px;
          }
        `}
      </style>
    </div>
  );
}

export default IconSection;
