import React from 'react';

import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Grid1Section(props) {
  return (
    <div className="site-section">
      <Container className="border-bottom">
        <Row>

          <Col md={{ order: 12 }}>
            <img
              src={props.image}
              width="100%"
              alt="Elderly couple using their device."
            />

          </Col>
          <Col md={{ order: 1 }}>
            <h1 className="sub-heading">WE CREATE STORIES</h1>
            <p className="block-41-text">
              Ultimately, we’d love to see a world where the idea of senior and youth isolation is no longer existent. Our world has 7.53 billion people in it. Nobody should ever have to be alone, especially when they’ve got a wealth of stories to share and a lifetime of experiences to recount.

            </p>
            <br />
            <a href="https://forms.gle/JcpWdx9rGKjHVysh9" className="nice-button">
              GET STARTED
            </a>

          </Col>

        </Row>
      </Container>

    </div>
  );
}
export default Grid1Section;
